exports.components = {
  "component---src-pages-about-founder-js": () => import("./../../../src/pages/about/founder.js" /* webpackChunkName: "component---src-pages-about-founder-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-mission-js": () => import("./../../../src/pages/about/mission.js" /* webpackChunkName: "component---src-pages-about-mission-js" */),
  "component---src-pages-about-why-js": () => import("./../../../src/pages/about/why.js" /* webpackChunkName: "component---src-pages-about-why-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-partner-js": () => import("./../../../src/pages/contact/partner.js" /* webpackChunkName: "component---src-pages-contact-partner-js" */),
  "component---src-pages-contact-sponsors-js": () => import("./../../../src/pages/contact/sponsors.js" /* webpackChunkName: "component---src-pages-contact-sponsors-js" */),
  "component---src-pages-contact-volunteer-js": () => import("./../../../src/pages/contact/volunteer.js" /* webpackChunkName: "component---src-pages-contact-volunteer-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-events-aakd-us-open-nyc-js": () => import("./../../../src/pages/events/aakd-us-open-nyc.js" /* webpackChunkName: "component---src-pages-events-aakd-us-open-nyc-js" */),
  "component---src-pages-events-atlanta-open-showdown-js": () => import("./../../../src/pages/events/atlanta-open-showdown.js" /* webpackChunkName: "component---src-pages-events-atlanta-open-showdown-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-aakd-js": () => import("./../../../src/pages/programs/aakd.js" /* webpackChunkName: "component---src-pages-programs-aakd-js" */),
  "component---src-pages-programs-after-school-js": () => import("./../../../src/pages/programs/after-school.js" /* webpackChunkName: "component---src-pages-programs-after-school-js" */),
  "component---src-pages-programs-index-js": () => import("./../../../src/pages/programs/index.js" /* webpackChunkName: "component---src-pages-programs-index-js" */),
  "component---src-pages-programs-pe-curriculum-js": () => import("./../../../src/pages/programs/pe-curriculum.js" /* webpackChunkName: "component---src-pages-programs-pe-curriculum-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */)
}

